// Generated by Framer (018ad06)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withMappedReactProps } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import ToggleButton, * as ToggleButtonInfo from "https://framerusercontent.com/modules/97jXLC37Utn57dF7effh/DQ0g4e2B9Ko9cL74qPec/YWyb6BbyX.js";
import { withDarkTheme, withLightTheme, withSystemTheme, withToggleTheme } from "https://framerusercontent.com/modules/zut3nAIjs6GSSyjpRvZG/mrsIJSHawybQjcu18NhX/ThemeToggle.js";
const ToggleButtonFonts = getFonts(ToggleButton);
const ToggleButtonWithSystemThemeWithMappedReactPropsa7nn8f = withMappedReactProps(withSystemTheme(ToggleButton), ToggleButtonInfo);
const ToggleButtonWithLightThemeWithMappedReactPropsa7nn8f = withMappedReactProps(withLightTheme(ToggleButton), ToggleButtonInfo);
const ToggleButtonWithDarkThemeWithMappedReactPropsa7nn8f = withMappedReactProps(withDarkTheme(ToggleButton), ToggleButtonInfo);
const MotionDivWithToggleTheme = withToggleTheme(motion.div);

const cycleOrder = ["rUwd4OodK"];

const serializationHash = "framer-Tiq9d"

const variantClassNames = {rUwd4OodK: "framer-v-oaap4x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({activeColor, height, id, inactiveColor, showSystemToggle, width, ...props}) => { return {...props, ap8tRvNEV: activeColor ?? props.ap8tRvNEV ?? "rgb(255, 255, 255)", r5hEqjYrq: showSystemToggle ?? props.r5hEqjYrq ?? true, w0swZ910C: inactiveColor ?? props.w0swZ910C ?? "rgb(115, 115, 115)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;activeColor?: string;inactiveColor?: string;showSystemToggle?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ap8tRvNEV, w0swZ910C, FoNgi4rUl, JZRwA9XP6, VoeubVoba, XWpR_EFsy, r5hEqjYrq, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "rUwd4OodK", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivWithToggleTheme {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-oaap4x", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rUwd4OodK"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}}>{r5hEqjYrq && (<ComponentViewportProvider ><motion.div className={"framer-o92onk-container"} layoutDependency={layoutDependency} layoutId={"sYfy0SoTw-container"}><ToggleButtonWithSystemThemeWithMappedReactPropsa7nn8f G6irDsNeL={ap8tRvNEV} height={"100%"} id={"sYfy0SoTw"} layoutId={"sYfy0SoTw"} pRHizpZ48={XWpR_EFsy} Q5dMDAlEN={w0swZ910C} style={{height: "100%", width: "100%"}} variant={"tctr8CtO4"} WA8k1PXV8={JZRwA9XP6} width={"100%"}/></motion.div></ComponentViewportProvider>)}<ComponentViewportProvider ><motion.div className={"framer-8wzssg-container"} layoutDependency={layoutDependency} layoutId={"RP71Zwgw2-container"}><ToggleButtonWithLightThemeWithMappedReactPropsa7nn8f G6irDsNeL={ap8tRvNEV} height={"100%"} id={"RP71Zwgw2"} layoutId={"RP71Zwgw2"} pRHizpZ48={FoNgi4rUl} Q5dMDAlEN={w0swZ910C} style={{height: "100%", width: "100%"}} variant={"PdjyyBgWf"} WA8k1PXV8={JZRwA9XP6} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-ao8yip-container"} layoutDependency={layoutDependency} layoutId={"GNZztEbfR-container"}><ToggleButtonWithDarkThemeWithMappedReactPropsa7nn8f G6irDsNeL={ap8tRvNEV} height={"100%"} id={"GNZztEbfR"} layoutId={"GNZztEbfR"} pRHizpZ48={VoeubVoba} Q5dMDAlEN={w0swZ910C} style={{height: "100%", width: "100%"}} variant={"PdjyyBgWf"} WA8k1PXV8={JZRwA9XP6} width={"100%"}/></motion.div></ComponentViewportProvider></MotionDivWithToggleTheme></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Tiq9d.framer-1q7dyjq, .framer-Tiq9d .framer-1q7dyjq { display: block; }", ".framer-Tiq9d.framer-oaap4x { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-Tiq9d .framer-o92onk-container, .framer-Tiq9d .framer-8wzssg-container, .framer-Tiq9d .framer-ao8yip-container { flex: none; height: 40px; position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Tiq9d.framer-oaap4x { gap: 0px; } .framer-Tiq9d.framer-oaap4x > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-Tiq9d.framer-oaap4x > :first-child { margin-left: 0px; } .framer-Tiq9d.framer-oaap4x > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"ap8tRvNEV":"activeColor","w0swZ910C":"inactiveColor","r5hEqjYrq":"showSystemToggle"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerlVUTMYKg1: React.ComponentType<Props> = withCSS(Component, css, "framer-Tiq9d") as typeof Component;
export default FramerlVUTMYKg1;

FramerlVUTMYKg1.displayName = "ThemeToggle";

FramerlVUTMYKg1.defaultProps = {height: 40, width: 120};

addPropertyControls(FramerlVUTMYKg1, {ap8tRvNEV: {defaultValue: "rgb(255, 255, 255)", title: "Active Color", type: ControlType.Color}, w0swZ910C: {defaultValue: "rgb(115, 115, 115)", title: "Inactive Color", type: ControlType.Color}, r5hEqjYrq: {defaultValue: true, title: "ShowSystemToggle", type: ControlType.Boolean}} as any)

addFonts(FramerlVUTMYKg1, [{explicitInter: true, fonts: []}, ...ToggleButtonFonts], {supportsExplicitInterCodegen: true})